import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import HomePage from './pages/HomePage'
import AboutPage from './pages/AboutPage'
import ScrollToTop from './components/ScrollToTop'
// import ServicesPage from './pages/ServicesPage'
// import NoPage from './pages/NoPage'

function App() {
  return (

  <>
    <BrowserRouter>
    <ScrollToTop />
    <Routes>
      {/* <Route index element={<Home />} /> */}
      <Route path='/' element={<HomePage/>} />
      <Route path='/home' element={<HomePage />} />
      <Route path='/about' element={<AboutPage />} />
      {/* <Route path='/services' element={<ServicesPage />} /> */}
      {/* <Route path='*' element={<NoPage />} /> */}
    </Routes>
  </BrowserRouter>
  </>
  )
}

export default App

