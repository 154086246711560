import React from 'react';
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button';


function ContactForm() {
  return (
    <Grid 
    container 
    spacing={3}
    padding={6} 
    direction={'column'}
    alignItems={'center'} 
    justifyContent={'center'}
    >
    {/* // sx={{ p: { xs: 2, sm: 3 }, m: { xs: 2, sm: 3 } }}> */}
      <Grid item xs={12} sm={6} container justifyContent={{ xs: 'center', sm: 'center', m: 'flex-start' }}>
        <Button
          variant="contained"
          size="large"
          href="mailto:elizabeth@millsapspublicstrategies.com?subject=I%20Look%20Forward%20To%20Talking%20To%20You"
          sx={{ 
            fontSize: { xs: '0.875rem', sm: '1rem' }, 
            padding: { xs: '8px 16px', sm: '12px 24px' } 
          }}
        >
          Contact Elizabeth
        </Button>
      </Grid>
    </Grid>
  );
}

export default ContactForm;





















// const ContactForm = () => {
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [comments, setComments] = useState('');

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     console.log('Submitted:', { name, email, phone, comments });
//     clearForm();
//     // Add your logic for handling the form submission (e.g., sending data to backend)
//   };

//   const clearForm = () => {
//     setName('');
//     setEmail('');
//     setPhone('');
//     setComments('');
//   };

//   return (
//     <div>
//       <Box sx={{ p: 3 }}> {/* Add padding of 3 (theme spacing units) */}
//       <Typography variant="h6" gutterBottom>
//         Contact Us
//       </Typography>
//       <form onSubmit={handleSubmit}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={12}>
//             <TextField
//               fullWidth
//               variant="outlined"
//               label="Name"
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//               required
//             />
//           </Grid>
//           <Grid item xs={12} sm={12}>
//             <TextField
//               fullWidth
//               variant="outlined"
//               label="Email"
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//             />
//           </Grid>
//           <Grid item xs={12} sm={12}>
//             <TextField
//               fullWidth
//               variant="outlined"
//               label="Phone Number"
//               value={phone}
//               onChange={(e) => setPhone(e.target.value)}
//               required
//             />
//           </Grid>
//           <Grid item xs={12} sm={12}>
//             <TextField
//               fullWidth
//               variant="outlined"
//               label="Comments"
//               multiline
//               rows={4}
//               value={comments}
//               onChange={(e) => setComments(e.target.value)}
//               required
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <Button
//               type="submit"
//               variant="contained"
//               color="primary"
//               fullWidth
//             >
//               Submit
//             </Button>
//           </Grid>
//         </Grid>
//       </form>
//       </Box>
//     </div>
//   );
// };


