import React from 'react'
import ContactForm from '../components/Contact'

function ContactUs() {
  return (
    <>
        <ContactForm />
    </>
  )
}

export default ContactUs