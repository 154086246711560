import React from 'react'
import AboutFull from '../components/AboutFull'
import Home from '../components/Home'

function AboutPage() {
  return (
    <>
        <Home />
        <AboutFull />
    </>
    
  )
}

export default AboutPage