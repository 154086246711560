import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import steps from '../assets/elizabeth_steps.jpg'
import outside from '../assets/elizabeth_crossed_arms.jpg'

// Styled component for Paper
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.primary,
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const About = () => {
  return (
    <Grid container spacing={1} sx={{ p: '20px' }}>
      {/* Top-left text */}
      <Grid item xs={12} md={6} sx={{ mb: 4 }} container justifyContent="flex-start" alignItems="flex-start" order={{ xs: 1, md: 0 }}>
        <StyledPaper elevation={3} sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h4" align='center'>
            "Elizabeth has over 25 years of political experience..."
          </Typography>
          <br />
          <Typography variant="body1">
            ...five of those years were spent engaging in the national landscape in Washington D.C. before she decided to bring her focus back to her home state of Tennessee in 2001.<br />
            <br />
            Since starting her own lobbying practice in 2010, Elizabeth has directly affected hundreds of legislative changes at the local and state level for nonprofits and businesses of all sizes. Her practice covers a broad range of issues including technology, law enforcement support, public education and healthcare. <br />
            <br />
            Elizabeth’s career is focused on building relationships based on trust and integrity to best advocate on behalf of her clients. Longstanding relationships with elected officials, state and city employees, and fellow government colleagues allow her to navigate the bureaucratic process to best meet the ever-changing needs of her clients.<br />
            <br />
            Elizabeth has worked with the American Cancer Society, the Tennessee Department of Safety, Homeland Security, and the Tennessee Department of Commerce and Insurance. 
          </Typography>
        </StyledPaper>
      </Grid>
      
      {/* Top-right photo */}
      <Grid item xs={12} md={6} sx={{ mb: 4 }} container justifyContent="center" alignItems="flex-start" order={{ xs: 0, md: 1 }}>
        <StyledPaper elevation={3} sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <img src={steps} alt="Top Right" style={{ width: 'auto', maxWidth: '100%', height: 'auto' }} />
        </StyledPaper>
      </Grid>
      
      {/* Bottom-left photo */}
      <Grid item xs={12} md={6} sx={{ mb: 4 }} container justifyContent="flex-start" alignItems="flex-end" order={{ xs: 2, md: 1 }}>
        <StyledPaper elevation={3} sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <img src={outside} alt="Bottom Left" style={{ width: 'auto', maxWidth: '100%', height: 'auto' }} />
        </StyledPaper>
      </Grid>
      
      {/* Bottom-right text */}
      <Grid item xs={12} md={6} sx={{ mb: 4 }} container justifyContent="center" alignItems="flex-end" order={{ xs: 4, md: 2 }}>
        <StyledPaper elevation={3} sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h4" align='center'>
            "Tennessean, through and through..."
          </Typography>
          <br />
          <Typography variant="body1">
          As a proud native of Tennessee, Elizabeth is proud to say that she has built a career on making her home state a better and safer place to build a business, find a home, and raise a family.<br />
          <br />
          She grew up farming the land in Lynchburg, she went to school at Middle Tennessee State University, and has daughter who is a proud Tennessee Vol.<br />
          <br />
          Elizabeth has helped our law enforcement communities find the tools they need to make our families and communities safer, has worked to protect Tennesseans from the ravages of the opioid crisis, advocated for our public schools, and helped businesses work to make state and local government more efficient.<br />
          <br />
          She serves as chairman of the board of the Tennessee Intercollegiate State Legislature, mentoring college students interested in government, and also on the board of directors of the YWCA of Nashville and Middle Tennessee. The YWCA works to empower women and eradicate domestic violence in our community.<br />
          <br />  
          </Typography>
        </StyledPaper>
      </Grid>
    </Grid>
  );
};

export default About;
