import React, { useState, useRef, useEffect } from 'react'
import { Paper, Typography, Grid, IconButton, Box, Collapse, useMediaQuery, useTheme } from '@mui/material';
import { AccountBalance, MonitorHeart, Feedback, Paid, Business } from '@mui/icons-material';

const iconData = [
  { icon: <AccountBalance fontSize="large" />, description: <div style={{ textAlign: 'center' }}>Advocacy & Lobbying</div>, additionalText: `Lobbying is all about know what message you need to share and who needs to hear that message. Elizabeth’s strength lies in getting that message to the right person at the right time.
  Rules of engagement for city council and legislative processes vary widely and can be overwhelming without professional guidance. Elizabeth can navigate those quickly and efficiently. This will help you best align yourself for success.` },

  { icon: <MonitorHeart fontSize="large"/>, description: <div style={{ textAlign: 'center' }}>Legislative Monitoring</div>, additionalText: `There are thousands of bills and resolutions filed each year in both state and local government. Elizabeth can monitor all of those to keep you informed and prepared. She will also help you prioritize which of those bills are likely to see legislative hearings and help you target your efforts on the most pressing issues so that your resources are used wisely.` },

  { icon: <Feedback fontSize="large"/>, description: <div style={{ textAlign: 'center' }}>Strategic Communication</div>, additionalText: `Issues that matter to the success of your business have to be communicated succinctly and directly from trusted voices. It is imperative that successful campaigns have clear, direct messages and we can help you shape them to accomplish your goals. Messaging matters. It’s important to know your goal, know what you need to say and know your audience. Elizabeth will help you have all three on track for success.` },

  { icon: <Paid fontSize="large"/>, description: <div style={{ textAlign: 'center' }}>Procurement & Funding</div>, additionalText: `State and local governments have rigid and cumbersome procurement processes, necessary to make sure that they are being good stewards of taxpayer dollars. It is important to have someone who can offer support to help you navigate those processes.
  Projects are often funded through legislative budget amendments. These require aggressive advocacy during very short windows of time each budget cycle. It is imperative to have someone who knows the process and is driven to work through such a difficult, rapidly changing process.` },

  { icon: <Business fontSize="large" />, description: <div style={{ textAlign: 'center' }}>Coalition Building</div>, additionalText: `Issues that matter to you often matter to other people. Effective advocacy requires aligning those people into one cohesive voices. Our elected officials hear from many voices on many issues every day. Building a coalition means clarifying and unifying those voices into a shared message on why we are working to make our community a better place. Longstanding Tennessee relationships mean that Elizabeth can work to bring those voices to the table to amplify your work.` },
];

const Services = () => {
  const [openIndex, setOpenIndex] = useState(-1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the view is mobile or tablet

  // Create refs for scrolling into view
  const collapseRefs = useRef([]);
  const desktopRef = useRef(null);

  // Scroll into view when the collapse expands
  useEffect(() => {
    if (isMobile && collapseRefs.current[openIndex]) {
      collapseRefs.current[openIndex].scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (!isMobile && desktopRef.current) {
      desktopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [openIndex, isMobile]);

  const handleIconClick = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };

  return (
    <Grid container spacing={3} padding={1}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center" sx={{ mb: -2 }}>
          What We Do
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          elevation={3}
          sx={{
            padding: '20px',
            margin: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'auto',
          }}
        >
          <Grid container spacing={3} justifyContent={"center"} alignItems={'center'}>
            {iconData.map((item, index) => (
              <Grid item xs={12} sm={12} md={2} key={index}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <IconButton
                    sx={{ fontSize: '50px' }}
                    onClick={() => handleIconClick(index)}
                  >
                    {item.icon}
                  </IconButton>
                  <Typography variant="h6" sx={{ marginTop: '10px', fontSize: '1.2rem' }}>
                    {item.description}
                  </Typography>
                  {/* Conditional rendering for mobile view */}
                  {isMobile && openIndex === index && (
                    <Collapse in={openIndex === index} timeout={5000} unmountOnExit sx={{
                      position: 'relative',
                      transition: 'opacity 0.8s ease-in-out', // Apply additional transitions if needed
                    }}>
                      <Box
                        ref={(el) => (collapseRefs.current[index] = el)}
                        sx={{
                          width: '100%',
                          padding: '10px',
                          backgroundColor: '#f5f5f5',
                          textAlign: 'center',
                          marginTop: '10px', // Margin to separate from icon
                        }}
                      >
                        {item.additionalText}
                      </Box>
                    </Collapse>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
          {/* Full-width expanded content for desktop view */}
          {!isMobile && (
            <Collapse in={openIndex !== -1} timeout={5000} unmountOnExit  sx={{
              position: 'relative',
              transition: 'opacity 0.8s ease-in-out', // Apply additional transitions if needed
            }}>
              <Box
                ref={desktopRef}
                sx={{
                  width: '100%',
                  padding: '10px',
                  backgroundColor: '#f5f5f5',
                  textAlign: 'center',
                  marginTop: '20px',
                  boxSizing: 'border-box',
                }}
              >
                {openIndex !== -1 && iconData[openIndex].additionalText}
              </Box>
            </Collapse>
          )}
        </Paper>
      </Grid> 
    </Grid>
  );
};



export default Services