import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import placeholderImage from '../assets/elizabeth_senate.jpg'
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const About = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/about')
  };

  return (
  <div>
         <Grid container spacing={3} style={{ padding: '20px' }}>
            <Grid item xs={12}>
              <Typography variant="h4" align='center' sx={{ mb: 2 }}>
                Our Mission
              </Typography>
              <Typography variant="body1" align='center'>
                Public policy change requires strategic direction and in-depth knowledge of the legislative process. Millsaps Public Strategies will help you target your goals and drive them to success.
              </Typography>
            </Grid>
          </Grid>

    <Grid container spacing={3} sx={{ p: 2 }}>

      {/* Image section */}
      <Grid item xs={12} md={6} container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
        <StyledPaper elevation={3}>
          <img src={placeholderImage} alt="About" style={{ width: 'auto', maxWidth: '100%', height: 'auto' }} />
        </StyledPaper>
      </Grid>
      
      {/* Text section */}
      <Grid item xs={12} md={6} container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
        <Paper elevation={3} sx={{ height: '100%', p: 3 }}>
          <Typography variant="h4" align='center' sx={{ mb: 2 }}>
            Who We Are
          </Typography>
          <Typography variant="body1" align='center'>
          Elizabeth Millsaps has over 25 years of political experience. She worked five years in Washington, DC, engaging in the national landscape, before moving closer to home in 2001 to focus on Tennessee. Her practice covers a broad range of issues including technology, law enforcement support, public education and healthcare. She represents both nonprofit clients and small and large business interests.<br/>
          <br/>
          Elizabeth’s career is focused on building relationships based on trust and integrity to best advocate on behalf of her clients. Longstanding relationships with elected officials, state and city employees, and fellow government colleagues allow her to navigate the bureaucratic process to best meet the ever-changing needs of her clients.<br/>
          <br/>
          </Typography>
          <Grid container justifyContent="center" sx={{ mt: 2, mb: 4 }}>
            <Button variant='contained' size='large' color='primary' onClick={handleClick} >
              Find Out More
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </div>
  );
};

export default About;
