import React from 'react'
import Home from '../components/Home'
import AboutUs from './AboutUs'
import ContactUs from './ContactUs'
import Services from '../components/Services'

function HomePage() {
  return (
    <>
    <Home />
    <AboutUs />
    <ContactUs />
    <Services />
    </>
  )
}

export default HomePage